<template>
  <div>
    <app-bar v-on:cacheCleared="updateLastUpdateStr"></app-bar>

    <v-main>

      <div class="d-flex flex-wrap justify-space-between align-center">
        <div class="d-md-none" style="padding: 20px">
          <!-- On smaller devices, show the toggles on top of each other -->
          <v-switch v-model="showCompleted" label="Show Completed"
            style="margin-top: 4px" hide-details></v-switch>
          <v-switch v-model="includeUninstallable" label="Include Uninstallable"
            style="margin-top: 4px" hide-details></v-switch>
        </div>
        <div class="d-none d-md-flex" style="padding: 20px">
          <!-- On wider devices, show the toggles next to each other -->
          <v-switch v-model="showCompleted" label="Show Completed"
            style="margin-top: 4px; margin-right: 10px" hide-details></v-switch>
          <v-switch v-model="includeUninstallable" label="Include Uninstallable"
            style="margin-top: 4px" hide-details></v-switch>
        </div>
        <div style="padding: 20px">
          <v-btn outlined class="d-md-none"
              v-on:click="showFullWidthSearch = !showFullWidthSearch">
            <!-- On medium and larger devices, don't show this icon. They get a full search field
            from the start. -->
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-text-field class="d-none d-md-flex" v-model="search" append-icon="mdi-magnify"
            label="Search" single-line hide-details style="padding-top: 0px; margin-top: 0px">
            <!-- Only show the search field here if they have a medium or larger device -->
          </v-text-field>
        </div>
        <div class="d-flex justify-end" style="padding: 20px">
          <div class="flex-column d-none d-sm-flex" style="padding-right: 10px; font-size: .8em">
            <!-- Don't show the string on very small devices -->
            <div>Last Updated</div>
            <div>{{lastUpdateStr}}</div>
          </div>
          <v-btn id="update-data-btn" outlined v-on:click="updateData()">
            <v-icon v-show="!loading">mdi-refresh</v-icon>
            <v-progress-circular v-show="loading" id="update-data-loading" indeterminate
              size="18" width="2" color="indigo"></v-progress-circular>
          </v-btn>
        </div>
      </div>
      <v-text-field v-show="showFullWidthSearch" class="d-md-none"
        v-model="search" append-icon="mdi-magnify" label="Search" single-line
        hide-details style="padding: 20px; margin-top: 0px">
        <!-- Don't show this text field on larger devices. They get the search bar in the middle
        of the row above. -->
      </v-text-field>

      <!--div class="d-none d-md-flex flex-wrap justify-space-between">
        <div style="padding: 20px">
          <v-switch v-model="showCompleted" style="margin-top: 4px" label="Show Completed"
              hide-details></v-switch>
        </div>
        <div style="padding: 20px">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
              hide-details style="padding-top: 0px; margin-top: 0px"></v-text-field>
        </div>
        <div class="d-flex justify-end" style="padding: 20px">
          <div class="flex-column d-none d-sm-flex" style="padding-right: 10px; font-size: .8em">
            <div>Last Updated</div>
            <div>{{lastUpdateStr}}</div>
          </div>
          <v-btn outlined v-on:click="updateData()">
            <v-icon v-if="!loading">mdi-refresh</v-icon>
            <v-progress-circular v-if="loading" indeterminate size="18" width="2" color="indigo">
            </v-progress-circular>
          </v-btn>
        </div>
      </div-->

      <div style="padding: 10px">
        <expandable-route-list v-for="route in routesToShow"
          style="padding-bottom: 10px"
          :key="route"
          :id="'route-' + route.replace(' ', '-')"
          :search="search"
          :loading="loading"
          :route-name="route"
          :meters="metersByRoute(route)"
          :headers="headers"
          :showCompleted="showCompleted"
          :includeUninstallable="includeUninstallable"></expandable-route-list>
      </div>

    </v-main>

  </div>
</template>

<script>
import Vue from 'vue';
import { appData, updateMeterData } from '../meter-data';
import logger from '../logger';
import expandableRouteList from '../components/expandable-route-list.vue';
import appBar from '../components/app-bar.vue';
import { METER_LIST_COLS } from '../meter-props';

export default {
    name: 'view-meters',
    components: {
        expandableRouteList: expandableRouteList,
        appBar: appBar,
    },
    data: function data() {
        const exportRoutes = Object.keys(appData.metersByRoute).sort();
        exportRoutes.splice(exportRoutes.indexOf('Test Meters'), 1);
        return {
            search: '',
            loading: false,
            drawer: false,
            appData: appData,
            showCompleted: appData.lastUsedSettings.showCompleted || false,
            includeUninstallable: appData.lastUsedSettings.includeUninstallable || false,
            showFullWidthSearch: false,
            lastUpdateStr: 'Never',
            updateStrIntervalId: 0,
            showUploadQueue: false,
            showImportWizard: false,
            showDataExport: false,
            dataLastUpdate: appData.lastUpdate,
        };
    },
    computed: {
        headers: function headers() {
            // const returnVal = [
            //     {
            //         text: 'Account Number',
            //         align: 'center',
            //         sortable: true,
            //         value: 'Account Number',
            //         class: 'd-none d-sm-table-cell',
            //     },
            //     {
            //         text: 'Install Type',
            //         align: 'center',
            //         sortable: true,
            //         value: 'Install Type',
            //         class: 'd-none d-sm-table-cell',
            //     },
            //     {
            //         text: 'Meter Size',
            //         align: 'center',
            //         sortable: true,
            //         value: 'Meter Size',
            //         class: 'd-none d-sm-table-cell',
            //     },
            //     {
            //         text: 'Service Address',
            //         align: 'left',
            //         sortable: false,
            //         value: 'Service Address',
            //         class: 'd-none d-sm-table-cell',
            //     },
            // ];
            const returnVal = [];
            for (let i = 0; i < METER_LIST_COLS.length; i++) {
                returnVal.push({
                    text: METER_LIST_COLS[i].name,
                    align: METER_LIST_COLS[i].align || 'center',
                    sortable: METER_LIST_COLS[i].sortable || true,
                    value: METER_LIST_COLS[i].key,
                    class: 'd-none d-sm-table-cell',
                });
            }
            if (this.showCompleted) {
                returnVal.unshift({
                    text: 'Completed',
                    align: 'center',
                    sortable: true,
                    value: 'Completed',
                    class: 'd-none d-sm-table-cell',
                });
            }
            return returnVal;
        },
        routesToShow: function routesToShow() {
            // Filter out the Test Meters route if we have a read-only user logged in
            const sortedRoutes = Object.keys(appData.metersByRoute).sort();
            if (appData.access === 'RO') {
                return sortedRoutes.filter((routeName) => routeName !== 'Test Meters');
            }
            return sortedRoutes;
        },
    },
    watch: {
        showCompleted: function showCompletedChanged(newValue) {
            const flogger = this.logger.getLogger('showCompletedChanged');
            flogger.logLevel = flogger.loggerLevels.debug;
            flogger.debug(`The Show Completed toggle changed to ${newValue}. Saving to app data last used settings.`);
            Vue.set(appData.lastUsedSettings, 'showCompleted', newValue);
        },
        includeUninstallable: function includeUninstallableChanged(newValue) {
            const flogger = this.logger.getLogger('includeUninstallableChanged');
            flogger.logLevel = flogger.loggerLevels.debug;
            flogger.debug(`The Include Uninstallable toggle changed to ${newValue}. Saving to app data last used settings.`);
            Vue.set(appData.lastUsedSettings, 'includeUninstallable', newValue);
        },
    },
    methods: {
        updateData: async function updateData() {
            this.loading = true;
            try {
                await updateMeterData();
            } catch (err) {
                console.log(err);
                // alert(`Unexpected error during update: ${err.message}`);
            }
            this.loading = false;
            clearInterval(this.updateStrIntervalId);
            this.updateLastUpdateStr();
        },
        updateLastUpdateStr: function updateLastUpdateStr() {
            const flogger = this.logger.getLogger('updateLastUpdateStr');
            flogger.debug('Updating last update string');
            let delayToNextUpdate = 1000 * 60;
            if (!appData.lastUpdate) {
                this.lastUpdateStr = 'Never';
                return;
            }
            const now = Date.now();
            if (now - appData.lastUpdate > 1000 * 60 * 60 * 24) {
                // Its been more than a day
                const daysAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60 * 60 * 24));
                if (daysAgo === 1) {
                    this.lastUpdateStr = '1 day ago';
                } else {
                    this.lastUpdateStr = `${daysAgo} days ago`;
                }
                delayToNextUpdate = 1000 * 60 * 60 * 24; // One Day
            } else if (now - appData.lastUpdate > 1000 * 60 * 60) {
                // Its been more than an hour
                const hoursAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60 * 60));
                if (hoursAgo === 1) {
                    this.lastUpdateStr = '1 hour ago';
                } else {
                    this.lastUpdateStr = `${hoursAgo} hours ago`;
                }
                delayToNextUpdate = 1000 * 60 * 60; // One Hour
            } else if (now - appData.lastUpdate > 1000 * 60) {
                // Its been more than an hour
                const minutesAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60));
                if (minutesAgo === 1) {
                    this.lastUpdateStr = '1 minute ago';
                } else {
                    this.lastUpdateStr = `${minutesAgo} minutes ago`;
                }
            } else {
                this.lastUpdateStr = 'A few seconds ago';
            }
            logger.debug(`Updating last update string to be '${this.lastUpdateStr}'. Next update in ${delayToNextUpdate} ms.`);
            this.updateStrIntervalId = setTimeout(this.updateLastUpdateStr, delayToNextUpdate);
        },
        metersByRoute: function metersByRoute(route) {
            return this.appData.metersByRoute[route];
        },
    },
    created: function created() {
        this.logger = logger.getLogger('meters');
        this.updateData();
    },
    beforeDestroy: function beforeDestroy() {
        clearInterval(this.updateStrIntervalId);
    },
};
</script>
