<template>
  <div>
    <app-bar v-on:cacheCleared="updateLastUpdateStr"></app-bar>

    <v-main>

      <div style="padding: 20px">

        <div class="d-flex flex-wrap justify-space-between">
          <div class="flex-grow-1" style="margin-right: 10px">
            <v-btn outlined class="d-sm-none"
                v-on:click="showFullWidthSearch = !showFullWidthSearch">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-text-field class="d-none d-sm-flex" v-model="search"
              @keydown.enter="updateFilteredMeters"
              label="Search" single-line hide-details style="padding-top: 0px; margin-top: 0px"
              id="search-text-field">
            </v-text-field>
          </div>
          <div>
            <v-btn outlined class="d-none d-sm-flex"
                  style="margin-right: 30px"
                  v-on:click="updateFilteredMeters">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
          <div class="d-flex justify-end">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-btn outlined v-on="on" style="margin-right: 10px">
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(col, index) in ALL_COLS"
                  :key="index"
                  @click="toggleColumn(col.text)"
                >
                  <v-list-item-title>
                    <v-icon v-if="showCols.includes(col)" style="margin-right: 32px">
                      mdi-checkbox-marked-outline
                    </v-icon>
                    <v-icon v-else style="margin-right: 32px">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    {{ col.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn outlined v-on:click="showFilterBar = !showFilterBar" style="margin-right: 10px">
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
            <v-btn outlined v-on:click="updateData()" style="margin-right: 10px">
              <v-icon v-if="!loading">mdi-refresh</v-icon>
              <v-progress-circular v-if="loading" indeterminate size="18" width="2" color="indigo">
              </v-progress-circular>
            </v-btn>
            <v-btn outlined v-on:click="exportData">
              <v-icon>mdi-file-export-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="showFullWidthSearch" style="padding-top: 20px"
            class="d-flex flex-wrap justify-space-between">
          <div class="flex-grow-1" style="margin-right: 10px">
            <v-text-field v-model="search"
              label="Search" single-line hide-details style="padding-top: 0px; margin-top: 0px"
              id="search-text-field">
            </v-text-field>
          </div>
          <div>
            <v-btn outlined
                  v-on:click="updateFilteredMeters()">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </div>

        <div style="text-align: right; padding-top: 5px; color: #666">
          Last Updated {{lastUpdateStr}}
        </div>
        <div v-if="showFilterBar">
          <div v-for="prop in filterableProps" v-bind:key="prop.key" class="filter-choice">
            <v-select v-if="[PROP_TYPES.SELECT, PROP_TYPES.SELECT_MULTI,
                PROP_TYPES.SELECT_EDITABLE].includes(prop.type)"
                :label="prop.name"
                v-model="filterValues[prop.key]"
                multiple
                :items="prop.choices"
                @change="updateFilteredMeters">
              <template v-slot:selection="{ index }">
                <span v-if="index === 0" class="grey--text caption">
                  {{ filterValues[prop.key].length }} of {{prop.choices.length}} Selected
                </span>
              </template>
            </v-select>
            <v-select v-if="prop.type === PROP_TYPES.BOOL"
                :label="prop.name"
                v-model="filterValues[prop.key]"
                multiple
                :items="[true, false]"
                @change="updateFilteredMeters">
              <template v-slot:selection="{ index }">
                <span v-if="index === 0" class="grey--text caption">
                  {{ filterValues[prop.key].length }} of 2 Selected
                </span>
              </template>
            </v-select>
          </div>
          <!--v-select label="Route" class="filter-choice"
              v-model="filter.routes"
              multiple
              :items="Object.keys(appData.metersByRoute).sort()"
              @change="updateFilteredMeters">
            <template v-slot:selection="{ index }">
              <span v-if="index === 0" class="grey--text caption">
                {{ filter.routes.length }} of {{ Object.keys(appData.metersByRoute).length }}
                Selected
              </span>
            </template>
          </v-select>
          <v-select label="Install Types" class="filter-choice"
              v-model="filter.installTypes"
              multiple
              :items="[
                { text: 'Standard Meter Replacement', value: 'Standard Meter Replacement' },
                { text: 'Hourly Rate', value: 'Hourly Rate' },
                { text: 'Not Defined', value: undefined },
              ]"
              @change="updateFilteredMeters">
            <template v-slot:selection="{ index }">
              <span v-if="index === 0" class="grey--text caption">
                {{ filter.installTypes.length }} of 3 Selected
              </span>
            </template>
          </v-select>
          <v-select label="Uninstallable" class="filter-choice" v-model="filter.uninstallable"
              multiple @change="updateFilteredMeters"
              :items="[{text: 'Yes', value: true}, {text: 'No', value: false}]">
            <template v-slot:selection="{ index }">
              <span v-if="index === 0" class="grey--text caption">
                {{ filter.uninstallable.length }} of 2 Selected
              </span>
            </template>
          </v-select>
          <v-select label="Completed" class="filter-choice" v-model="filter.completed"
              multiple @change="updateFilteredMeters"
              :items="[{text: 'Yes', value: true}, {text: 'No', value: false}]">
            <template v-slot:selection="{ index }">
              <span v-if="index === 0" class="grey--text caption">
                {{ filter.completed.length }} of 2 Selected
              </span>
            </template>
          </v-select>
          <v-select label="Town Attention Needed" class="filter-choice"
              v-model="filter.townAttentionNeeded" multiple @change="updateFilteredMeters"
              :items="[{text: 'Yes', value: true}, {text: 'No', value: false}]">
            <template v-slot:selection="{ index }">
              <span v-if="index === 0" class="grey--text caption">
                {{ filter.townAttentionNeeded.length }} of 2 Selected
              </span>
            </template>
          </v-select>
          <v-select label="Unsaved Changes" class="filter-choice"
              v-model="filter.unsavedChanges" multiple @change="updateFilteredMeters"
              :items="[{text: 'Yes', value: true}, {text: 'No', value: false}]">
            <template v-slot:selection="{ index }">
              <span v-if="index === 0" class="grey--text caption">
                {{ filter.unsavedChanges.length }} of 2 Selected
              </span>
            </template>
          </v-select-->
        </div>

        <v-data-table :items="filteredMeters" :headers="showCols"
            :loading="loading" hide-default-footer :mobile-breakpoint="0" disable-pagination
            fixed-header height="100vh" style="margin-top: 20px"
            multi-sort v-bind:sort-by.sync="sortBy" v-bind:sort-desc.sync="sortDesc"
            ref="dataTable">
          <template v-slot:item="props">
            <tr :id="props.item.ID" style="cursor: pointer"
                v-on:click="goToMeterDetails(props.item['ID'])">
              <td v-for="header in props.headers" :key="header.value"
                  v-bind:class="{ [`text-${header.align || 'start'}`]: true }">
                <span v-if="METER_PROPS_BY_KEY[header.value]
                    && METER_PROPS_BY_KEY[header.value].type === Boolean">
                  <v-icon v-if="props.item[header.value]">mdi-check</v-icon>
                </span>
                <span v-else-if="METER_PROPS_BY_KEY[header.value]
                    && METER_PROPS_BY_KEY[header.value].type === PROP_TYPES.ISO_TIMESTAMP">
                  {{new Date(props.item[header.value]).toLocaleString()}}
                </span>
                <span v-else>
                  {{ props.item[header.value] }}
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            No meters found
          </template>
        </v-data-table>
      </div>
    </v-main>

  </div>
</template>

<script>
import papa from 'papaparse';
import { saveAs } from 'file-saver';
import { appData, updateMeterData } from '../meter-data';
import { WINDOW_TITLE } from '../site-consts';
import {
    METER_PROPS, METER_PROPS_BY_KEY, METER_PROPS_BY_NAME, PROP_TYPES,
    METER_LIST_COLS, ROUTE_KEY,
} from '../meter-props';
import logger from '../logger';
import appBar from '../components/app-bar.vue';

const moduleLogger = logger.getLogger('meters');
// moduleLogger.logLevel = moduleLogger.loggerLevels.debug;

export default {
    name: 'view-meters',
    components: {
        appBar: appBar,
    },
    data: function data() {
        const flogger = moduleLogger.getLogger('data');
        flogger.debug('In Vue data() function');
        console.log(METER_PROPS);
        const filterValues = {};
        for (let i = 0; i < METER_PROPS.length; i++) {
            const prop = METER_PROPS[i];
            if ([PROP_TYPES.SELECT, PROP_TYPES.SELECT_MULTI,
                PROP_TYPES.SELECT_EDITABLE].includes(prop.type)
            ) {
                filterValues[prop.key] = [...prop.choices];
            }
            if (prop.type === PROP_TYPES.BOOL) {
                filterValues[prop.key] = [true, false];
            }
        }
        // TODO Find a better way to remove the test route from the selected list
        filterValues[ROUTE_KEY] = Object.keys(appData.metersByRoute).sort().slice(0, -1);
        filterValues.Completed = [true, false];
        filterValues['Unsaved Changes'] = [true, false];
        return {
            search: '',
            filteredMeters: [...appData.meters],
            metersRemoved: [],
            loading: false,
            appData: appData,
            showFullWidthSearch: false,
            lastUpdateStr: 'Never',
            updateStrIntervalId: 0,
            showFilterBar: false,
            showCols: [...METER_LIST_COLS],
            filter: {
                routes: Object.keys(appData.metersByRoute).sort().slice(0, -1),
                installTypes: ['Standard Meter Replacement', 'Hourly Rate', undefined],
                uninstallable: [true, false],
                completed: [true, false],
                townAttentionNeeded: [true, false],
                meterSize: [
                    '0', '0.58', '0.75', '1', '1.25', '1.5', '2', '3', '4', '5', '6', '7', '8',
                ],
                unsavedChanges: [true, false],
            },
            filterValues: filterValues,
            // ALL_COLS: ALL_COLS,
            ALL_COLS: METER_PROPS.sort((a, b) => {
                console.log(a);
                return a.text.localeCompare(b.text);
            }),
            allColsByName: METER_PROPS_BY_NAME,
            // allColsByName: allColsByName,
            sortBy: [],
            sortDesc: [],
            METER_PROPS_BY_KEY: METER_PROPS_BY_KEY,
            METER_PROPS: METER_PROPS,
            PROP_TYPES: PROP_TYPES,
            ROUTE_KEY: ROUTE_KEY,
            allMeters: appData.meters,
        };
    },
    methods: {
        toggleColumn: function toggleColumn(colName) {
            const col = this.allColsByName[colName];
            const index = this.showCols.indexOf(col);
            if (index === -1) {
                this.showCols.push(col);
            } else {
                this.showCols.splice(index, 1);
            }
            this.updateUrl();
        },
        // filterRow: function filterRow(value, search, item) {
        //     if (this.metersAlreadySearched[item.ID]) {
        //         console.log('already found');
        //         return this.metersAlreadySearched[item.ID];
        //     }
        //     this.metersSearched += 1;
        //     // Some notes: In order for this function to get run, we need to always have a
        //     // search value. Return true if the row should be displayed
        //     if (!window.meterCount) {
        //         window.meterCount = {};
        //     }
        //     if (!window.meterCount[item.ID]) {
        //         window.meterCount[item.ID] = 0;
        //     }
        //     window.meterCount[item.ID] += 1;
        //     if (Object.keys(window.meterCount).length === 1) {
        //         console.log(value);
        //     }
        //     // const uninstallable = item.Uninstallable || false;
        //     // const completed = item.Completed || false;
        //     // const townAttention = item['Town Attention Needed'] || false;
        //     // return value != null
        //     //   && this.filter.routes.includes(item.Route)
        //     //   && this.filter.uninstallable.includes(uninstallable)
        //     //   && this.filter.installTypes.includes(item['Install Type'])
        //     //   && this.filter.completed.includes(completed)
        //     //   && this.filter.meterSize.includes(item['Meter Size'])
        //     //   && this.filter.townAttentionNeeded.includes(townAttention)
        //     //   && this.filter.unsavedChanges.includes(
        //     // appData.modifiedMeters[item.ID] !== undefined)
        //     //   && this.search != null
        //     //   && JSON.stringify(item).toLowerCase().includes(this.search.toLowerCase());
        //     // const fHalf = this.firstHalf(value, item);
        //     // console.log(fHalf);
        //     // const sHalf = this.secondHalf(value, item);
        //     // console.log(sHalf);
        //     // return fHalf && sHalf;
        //     this.metersAlreadySearched[item.ID] = this.firstHalf(value, item)
        //         && this.secondHalf(value, item);
        //     return this.metersAlreadySearched[item.ID];
        // },
        updateFilteredMeters: function updateFilteredMeters() {
            // TODO This needs to be updated to not be custom for every job
            const FILTER_DEBUG = false;
            const flogger = moduleLogger.getLogger('updateFilteredMeters');
            this.filteredMeters = [];
            this.metersRemoved = [];
            for (let i = 0; i < appData.meters.length; i++) {
                const meter = appData.meters[i];
                const uninstallable = meter.Uninstallable || false;
                const completed = meter.Completed || false;
                const townAttention = meter['Town Attention Needed'] || false;
                const installType = meter['Install Type'] || 'Replace Meter';
                if (FILTER_DEBUG && meter.ID === '3dc3f4ee-440a-4e22-b467-5f2caeb0381f') {
                    flogger.debug(`Matches route filter: ${this.filter.routes.includes(meter['Route Name'])}`);
                    flogger.debug(`Matches uninstallable filter: ${this.filter.uninstallable.includes(uninstallable)}`);
                    flogger.debug(`Matches install types filter: ${this.filter.installTypes.includes(installType)}`);
                    flogger.debug(`Matches completed filter: ${this.filter.completed.includes(completed)}`);
                    // flogger.debug(`Matches meter size filter: ` +
                    //     ${this.filter.meterSize.includes(meter['Meter Size'])}`);
                    flogger.debug(`Matches town attention needed filter: ${this.filter.townAttentionNeeded.includes(townAttention)}`);
                    flogger.debug(`Matches unsaved changes filter: ${this.filter.unsavedChanges.includes(
                        appData.modifiedMeters[meter.ID] !== undefined,
                    )}`);
                    flogger.debug(`Matches search filter: ${this.jstring(meter).toLowerCase().includes(this.search.toLowerCase())}`);
                }
                if (this.filterValues.Uninstallable.includes(uninstallable)
                    // && this.filter.installTypes.includes(installType)
                    && this.filterValues.Completed.includes(completed)
                    // && this.filter.meterSize.includes(meter['Meter Size'])
                    && this.filterValues['Town Attention Needed'].includes(townAttention)
                    && this.filter.unsavedChanges.includes(
                        appData.modifiedMeters[meter.ID] !== undefined,
                    )
                    && this.jstring(meter).toLowerCase().includes(this.search.toLowerCase())) {
                    this.filteredMeters.push(appData.meters[i]);
                } else {
                    // console.log({
                    //     routes: this.filter.routes.includes(meter['Route Name']),
                    //     uninstallable: this.filter.uninstallable.includes(uninstallable),
                    //     installTypes: this.filter.installTypes.includes(
                    //         meter['Install Type']
                    //     ),
                    //     completed: this.filter.completed.includes(completed),
                    //     meterSize: this.filter.meterSize.includes(meter['Meter Size']),
                    //     townAttentionNeeded: this.filter.townAttentionNeeded.includes(
                    //         townAttention,
                    //     ),
                    //     unsavedChanges: this.filter.unsavedChanges.includes(
                    //         appData.modifiedMeters[meter.ID] !== undefined,
                    //     ),
                    //     search: this.jstring(meter).toLowerCase().includes(
                    //         this.search.toLowerCase(),
                    //     ),
                    // });
                    // console.log(meter);
                    this.metersRemoved.push(appData.meters[i]);
                }
                // if (i === 0) {
                //     console.log(this.firstHalf('s', appData.meters[i]));
                //     console.log(this.secondHalf('s', appData.meters[i]));
                // }
            }
            this.updateUrl();
        },
        firstHalf: function firstHalf(value, item) {
            const uninstallable = item.Uninstallable || false;
            const completed = item.Completed || false;
            const townAttention = item['Town Attention Needed'] || false;
            // console.log(this.filter);
            // console.log(item);
            // console.log(value != null);
            // console.log(this.filter.routes.includes(item['Route Name']));
            // console.log(this.filter.uninstallable.includes(uninstallable));
            // console.log(this.filter.installTypes.includes(item['Install Type']));
            // console.log(this.filter.completed.includes(completed));
            // console.log(this.filter.meterSize.includes(item['Meter Size']));
            // console.log(this.filter.townAttentionNeeded.includes(townAttention));
            // console.log(this.filter.unsavedChanges.includes(
            //     appData.modifiedMeters[item.ID] !== undefined,
            // ));
            // console.log(this.search != null);
            return value != null
              && this.filter.routes.includes(item['Route Name'])
              && this.filter.uninstallable.includes(uninstallable)
              && this.filter.installTypes.includes(item['Install Type'])
              && this.filter.completed.includes(completed)
              && this.filter.meterSize.includes(item['Meter Size'])
              && this.filter.townAttentionNeeded.includes(townAttention)
              && this.filter.unsavedChanges.includes(appData.modifiedMeters[item.ID] !== undefined)
              && this.search != null;
        },
        secondHalf: function secondHalf(value, item) {
            return this.jstring(item).toLowerCase().includes(this.search.toLowerCase());
        },
        jstring: function jstring(str) {
            return JSON.stringify(str);
        },
        goToMeterDetails: function goToMeterDetails(meterId) {
            this.$router.push(`/meters/${meterId}`);
        },
        updateData: async function updateData() {
            const flogger = moduleLogger.getLogger('updateData');
            flogger.info('Update Data button pressed');
            this.loading = true;
            try {
                flogger.debug('Calling updateMeterData() ...');
                await updateMeterData();
                flogger.debug('Call to updateMeterData() complete');
            } catch (err) {
                flogger.error(`There was an unexpected error when calling updateMeterData: ${JSON.stringify(err)}`);
            }
            this.loading = false;
            clearInterval(this.updateStrIntervalId);
            this.updateLastUpdateStr();
        },
        exportData: async function exportData() {
            const headers = this.$refs.dataTable.computedHeaders.map(
                (headerProps) => headerProps.value,
            );
            const exportFileName = `Meters_${new Date().toISOString().replace(':', '.')}.csv`;
            const csvStr = papa.unparse(this.$refs.dataTable.internalCurrentItems, {
                quotes: true,
                columns: headers,
                header: true,
            });
            saveAs(new Blob([csvStr], { type: 'text/csv;charset=utf-8' }), exportFileName);
        },
        updateLastUpdateStr: function updateLastUpdateStr() {
            const flogger = moduleLogger.getLogger('updateLastUpdateStr');
            flogger.debug('Updating last update string');
            let delayToNextUpdate = 1000 * 60;
            if (!appData.lastUpdate) {
                this.lastUpdateStr = 'Never';
                return;
            }
            const now = Date.now();
            if (now - appData.lastUpdate > 1000 * 60 * 60 * 24) {
                // Its been more than a day
                const daysAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60 * 60 * 24));
                if (daysAgo === 1) {
                    this.lastUpdateStr = '1 day ago';
                } else {
                    this.lastUpdateStr = `${daysAgo} days ago`;
                }
                delayToNextUpdate = 1000 * 60 * 60 * 24; // One Day
            } else if (now - appData.lastUpdate > 1000 * 60 * 60) {
                // Its been more than an hour
                const hoursAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60 * 60));
                if (hoursAgo === 1) {
                    this.lastUpdateStr = '1 hour ago';
                } else {
                    this.lastUpdateStr = `${hoursAgo} hours ago`;
                }
                delayToNextUpdate = 1000 * 60 * 60; // One Hour
            } else if (now - appData.lastUpdate > 1000 * 60) {
                // Its been more than an hour
                const minutesAgo = Math.floor((now - appData.lastUpdate) / (1000 * 60));
                if (minutesAgo === 1) {
                    this.lastUpdateStr = '1 minute ago';
                } else {
                    this.lastUpdateStr = `${minutesAgo} minutes ago`;
                }
            } else {
                this.lastUpdateStr = 'A few seconds ago';
            }
            flogger.debug(`Updating last update string to be '${this.lastUpdateStr}'. Next update in ${delayToNextUpdate} ms.`);
            this.updateStrIntervalId = setTimeout(this.updateLastUpdateStr, delayToNextUpdate);
        },
        updateUrl: function updateUrl() {
            const searchParams = new URLSearchParams(document.location.search);
            const origRoutes = JSON.stringify(
                Object.keys(appData.metersByRoute).sort().slice(0, -1),
            );
            if (JSON.stringify(this.filter.routes) !== origRoutes) {
                searchParams.set('routes', this.filter.routes.join(','));
            } else {
                searchParams.delete('routes');
            }
            if (this.filter.installTypes.length < 3) {
                searchParams.set('installTypes', this.filter.installTypes.join(','));
            } else {
                searchParams.delete('installTypes');
            }
            if (this.filter.uninstallable.length < 2) {
                searchParams.set('uninstallable', this.filter.uninstallable.join(','));
            } else {
                searchParams.delete('uninstallable');
            }
            if (this.filter.completed.length < 2) {
                searchParams.set('completed', this.filter.completed.join(','));
            } else {
                searchParams.delete('completed');
            }
            if (this.filter.meterSize.length < 7) {
                searchParams.set('meterSize', this.filter.meterSize.join(','));
            } else {
                searchParams.delete('meterSize');
            }
            if (this.filter.townAttentionNeeded.length < 2) {
                searchParams.set('townAttentionNeeded', this.filter.townAttentionNeeded.join(','));
            } else {
                searchParams.delete('townAttentionNeeded');
            }
            if (this.filter.unsavedChanges.length < 2) {
                searchParams.set('unsavedChanges', this.filter.unsavedChanges.join(','));
            } else {
                searchParams.delete('unsavedChanges');
            }
            if (this.search) {
                searchParams.set('search', this.search);
            } else {
                searchParams.delete('search');
            }
            if (JSON.stringify(this.showCols.map((header) => header.text))
                === JSON.stringify(METER_LIST_COLS.map((header) => header.text))) {
                searchParams.delete('columns');
            } else {
                searchParams.set('columns', this.showCols.map((header) => header.text).join(','));
            }
            if (this.sortBy.length > 0) {
                searchParams.set('sortBy', this.sortBy.join(','));
            } else {
                searchParams.delete('sortBy');
            }
            if (this.sortDesc.length > 0) {
                searchParams.set('sortDesc', this.sortDesc.join(','));
            } else {
                searchParams.delete('sortDesc');
            }
            const searchParamsStr = searchParams.toString();
            const searchStr = searchParamsStr.length > 0 ? `?${searchParamsStr}` : '';
            const newUrl = `${document.location.pathname}${searchStr}`;
            window.history.replaceState(null, document.title, newUrl);
        },
    },
    created: function created() {
        document.title = `${WINDOW_TITLE} Meters`;
        this.updateData();
        // See if we have filters defined on the URL
        if (this.$route.query.routes) {
            this.filter.routes = this.$route.query.routes.split(',');
        }
        if (this.$route.query.installTypes) {
            this.filter.installTypes = this.$route.query.installTypes.split(',');
        }
        if (this.$route.query.uninstallable) {
            this.filter.uninstallable = this.$route.query.uninstallable.split(',');
            for (let i = 0; i < this.filter.uninstallable.length; i++) {
                if (this.filter.uninstallable[i].toLowerCase() === 'true') {
                    this.filter.uninstallable[i] = true;
                } else {
                    this.filter.uninstallable[i] = false;
                }
            }
        }
        if (this.$route.query.completed) {
            this.filter.completed = this.$route.query.completed.split(',');
            for (let i = 0; i < this.filter.completed.length; i++) {
                if (this.filter.completed[i].toLowerCase() === 'true') {
                    this.filter.completed[i] = true;
                } else {
                    this.filter.completed[i] = false;
                }
            }
        }
        if (this.$route.query.townAttentionNeeded) {
            this.filter.townAttentionNeeded = this.$route.query.townAttentionNeeded.split(',');
            for (let i = 0; i < this.filter.townAttentionNeeded.length; i++) {
                if (this.filter.townAttentionNeeded[i].toLowerCase() === 'true') {
                    this.filter.townAttentionNeeded[i] = true;
                } else {
                    this.filter.townAttentionNeeded[i] = false;
                }
            }
        }
        if (this.$route.query.unsavedChanges) {
            this.filter.unsavedChanges = this.$route.query.unsavedChanges.split(',');
            for (let i = 0; i < this.filter.unsavedChanges.length; i++) {
                if (this.filter.unsavedChanges[i].toLowerCase() === 'true') {
                    this.filter.unsavedChanges[i] = true;
                } else {
                    this.filter.unsavedChanges[i] = false;
                }
            }
        }
        if (this.$route.query.meterSize) {
            this.filter.meterSize = this.$route.query.meterSize.split(',');
        }
        if (this.$route.query.columns) {
            this.showCols = [];
            const colNames = this.$route.query.columns.split(',');
            for (let i = 0; i < colNames.length; i++) {
                if (this.allColsByName[colNames[i]] === undefined) {
                    continue;
                }
                this.showCols.push(this.allColsByName[colNames[i]]);
            }
        }
        if (this.$route.query.search) {
            this.search = this.$route.query.search;
        }
        if (this.$route.query.sortBy) {
            this.sortBy = this.$route.query.sortBy.split(',');
        }
        if (this.$route.query.sortDesc) {
            this.sortDesc = this.$route.query.sortDesc.split(',');
        }
        const keyVals = {};
        for (let i = 0; i < appData.meters.length; i++) {
            const meter = appData.meters[i];
            const meterKeys = Object.keys(meter);
            for (let j = 0; j < meterKeys.length; j++) {
                if (keyVals[meterKeys[j]] === undefined) {
                    keyVals[meterKeys[j]] = new Set();
                }
                if (keyVals[meterKeys[j]].length > 20) {
                    continue;
                }
                keyVals[meterKeys[j]].add(meter[meterKeys[j]]);
            }
        }
        this.updateFilteredMeters();
    },
    computed: {
        filterableProps: function computedFilterableProps() {
            return METER_PROPS.filter(
                (prop) => [
                    PROP_TYPES.SELECT, PROP_TYPES.SELECT_MULTI, PROP_TYPES.SELECT_EDITABLE,
                    PROP_TYPES.BOOL,
                ].includes(prop.type),
            );
        },
    },
    // mounted: function mounted() {
    //     // Without this event listener on compositionupdate, android keyboard behavior feels
    //     // strange. Our search variable won't get updated until you tap the suggested word up
    //     // top.
    //     document.getElementById('search-text-field').addEventListener(
    // 'compositionupdate', () => {
    //         // console.log('setting nextick');
    //         // console.log('composition update');
    //         // console.log(`Value: ${document.getElementById('search-text-field').value}`);
    //         // console.log(event);
    //         this.search = document.getElementById('search-text-field').value;
    //         // this.search = event.data;
    //         // this.$forceUpdate();
    //         // Vue.nextTick(this.$forceUpdate.bind(this));
    //         // console.log('end composition');
    //     });
    //     document.getElementById('full-width-search-text-field').addEventListener(
    // 'compositionupdate', () => {
    //         // console.log('composition update');
    //         this.search = document.getElementById('full-width-search-text-field').value;
    //         // this.$forceUpdate();
    //         // Vue.nextTick(this.$forceUpdate.bind(this));
    //         // console.log('end composition');
    //     });
    // },
    // beforeUpdate: function beforeUpdate() {
    //     console.log(`Before update ${this.search}`);
    // },
    // updated: function updated() {
    //     console.log(`updated ${this.search}`);
    // },
    watch: {
        search: function searchChanged() {
            const flogger = moduleLogger.getLogger('searchChanged');
            flogger.debug(`The search variable changed to ${this.search}`);
            this.updateUrl();
        },
        sortBy: function sortByChanged() {
            const flogger = moduleLogger.getLogger('sortByChanged');
            flogger.debug(`The sortBy variable changed to ${this.sortBy}`);
            this.updateUrl();
        },
        sortDesc: function sortDescChanged() {
            const flogger = moduleLogger.getLogger('sortDescChanged');
            flogger.debug(`The sortDesc variable changed to ${this.sortDesc}`);
            this.updateUrl();
        },
        allMeters: function allMetersChanged() {
            this.updateFilteredMeters();
        },
    },
    activated: function activated() {
        document.title = `${WINDOW_TITLE} Meters`;
    },
    beforeDestroy: function beforeDestroy() {
        clearInterval(this.updateStrIntervalId);
    },
};
</script>
