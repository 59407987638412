const API_BASE = 'https://bowling-green-meter-swap.carlsapp.com/api';  // REPLACE_ME
const STORAGE_KEY = 'MeterData';
const WINDOW_TITLE = 'Bowling Green Meter Swap';  // REPLACE_ME
const APP_VERSION = '2.20';
const DEFAULT_IMPORT_FILE_FORMAT = 'Fixed Width';

// To use this file in a CommonJS environment replace export with module.exports =.
export {
    API_BASE, STORAGE_KEY, WINDOW_TITLE, APP_VERSION,
    DEFAULT_IMPORT_FILE_FORMAT,
};
