<template>
  <div>
    <v-navigation-drawer v-model="drawerOpen" v-if="!$route.meta.hideNav" app>
      <v-list dense>
        <v-list-item v-on:click.stop="drawerOpen = false; $router.push('/meters')">
          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Meters</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-on:click.stop="drawerOpen = false; $router.push('/meters-by-route')">
          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Meters by Route</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-on:click.stop="drawerOpen = false;
            $router.push({path: 'meters', query: { unsavedChanges: 'true' } })">
          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Unsaved Changes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-on:click.stop="showImportWizard = true; drawerOpen = false">
          <v-list-item-action>
            <v-icon>mdi-upload</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Import Route</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="showDataExport = true; drawerOpen = false">
          <v-list-item-action>
            <v-icon>mdi-file-download-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
              <v-list-item-title>Export Data</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="clearDeviceCache">
          <v-list-item-action>
            <v-icon>mdi-delete-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Clear Device Cache</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="showDeviceId">
          <v-list-item-action>
            <v-icon>mdi-info-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Show Device ID</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app v-if="!$route.meta.hideNav" color="indigo" dark dense>
      <v-app-bar-nav-icon @click.stop="drawerOpen = !drawerOpen"></v-app-bar-nav-icon>
      <v-toolbar-title id="toolbar-title">{{WINDOW_TITLE}} v{{APP_VERSION}}</v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-btn id="upload-dialog-btn" icon style="margin-right: 20px"
          v-if="appData.access !== 'RO'"
          v-on:click.stop="showUploadQueue = true">
        <span id="upload-count" style="display: none">
          {{appData.uploadQueue.inProgress.length + appData.uploadQueue.queue.length}}
        </span>
        <v-badge id="upload-count-badge"
            v-show="appData.uploadQueue.inProgress.length + appData.uploadQueue.queue.length > 0"
            color="orange" overlap>
          <template v-slot:badge>
            <span>
              {{appData.uploadQueue.inProgress.length + appData.uploadQueue.queue.length}}
            </span>
          </template>
          <v-icon>mdi-cloud-upload</v-icon>
        </v-badge>
        <v-icon
            v-show="appData.uploadQueue.inProgress.length + appData.uploadQueue.queue.length === 0">
          mdi-cloud-upload
        </v-icon>
      </v-btn>
      <v-icon @click="logout">
        mdi-logout
      </v-icon>
    </v-app-bar>

    <upload-queue-dialog v-model="showUploadQueue" v-bind:queue="appData.uploadQueue"/>
    <import-dialog v-model="showImportWizard"/>
    <export-dialog v-model="showDataExport"/>
  </div>
</template>

<script>
import { API_BASE, APP_VERSION, WINDOW_TITLE } from '../site-consts';
import { appData, periodicSaveOfAppDataToStorage2 } from '../meter-data';
import logger from '../logger';
import UploadQueue from '../UploadQueue';
import exportDialog from './export-dialog.vue';
import importDialog from './import-dialog.vue';
import uploadQueueDialog from './upload-queue-dialog.vue';

export default {
    name: 'app-bar',
    components: {
        exportDialog: exportDialog,
        importDialog: importDialog,
        uploadQueueDialog: uploadQueueDialog,
    },
    data: function data() {
        return {
            drawerOpen: false,
            appData: appData,
            showUploadQueue: false,
            showImportWizard: false,
            showDataExport: false,
            APP_VERSION: APP_VERSION,
            WINDOW_TITLE: WINDOW_TITLE,
        };
    },
    methods: {
        clearDeviceCache: function clearDeviceCache() {
            appData.meters = [];
            appData.metersById = {};
            appData.metersByRoute = {};
            appData.uploadQueue = new UploadQueue();
            appData.lastUpdate = null;
            this.drawerOpen = false;
            this.$emit('cacheCleared');
        },
        logout: async function logout() {
            const flogger = this.logger.getLogger('logout');
            // flogger.logLevel = flogger.loggerLevels.debug;
            flogger.info('Logout button pressed. Logging the user out and redirecting to /login.');
            // Call the /logout endpoint to clear our access_code cookie
            await fetch(`${API_BASE}/logout`, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
            });
            flogger.debug('Call to /logout complete. Setting app data ...');
            appData.access = undefined;
            appData.authenticated = false;
            // We can't depend on the app data watcher b/c it triggers a save to local storage
            // after 5 seconds. But, when we navigate to /login, it'll reload from local storage.
            // So, we need to call periodicSaveAppDataToStorage() to save immediately.
            periodicSaveOfAppDataToStorage2();
            flogger.debug('App data updated. Going to /login path ...');
            this.$router.go('/login');
        },
        showDeviceId: async function showDeviceId() {
            const resp = await fetch(`${API_BASE}/client_id`, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
            });
            alert(await resp.text());
        },
    },
    created: function created() {
        this.logger = logger.getLogger('app-bar');
        // this.logger.logLevel = this.logger.loggerLevels.debug;
    },
};
</script>
