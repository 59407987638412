// This file contains details about the meter properties that this project will have. It was
// originally created to fix a circular dependency that existed between
// site-consts -> logger -> site-consts by removing all imports from site-consts.
import appLogger from './logger';

const mlogger = appLogger.getLogger('site-consts');
// mlogger.logLevel = mlogger.loggerLevels.debug;

const ROUTE_KEY = 'Route Name';
const NUM_DETAILS_SHOW = 3;

const PROP_TYPES = {
    BOOL: Boolean,
    STRING: String,
    NUMBER: Number,
    SELECT: 'Select',
    SELECT_MULTI: 'Select Multiple',
    SELECT_EDITABLE: 'Select Editable',
    BARCODE: 'Barcode',
    TEXTAREA: 'Text Area',
    DATE: 'Date',
    ISO_TIMESTAMP: 'ISO Timestamp',
    INSTALL_TYPE: 'Install Type',
    CREW_WITH_HOURS: 'Crew With Hours',
    DIMENSION: 'Dimension',
};

const FEATURE_TOGGLES = {
    GPS_EDITABLE: true,
};

// The meter props is a list of all the properties we have for the meters
//   key - The meter property name that contains the value for this property meter[prop.key]
//   type - The type of the prop. Possible values: Boolean, String, Number
//   TODO Complete this list
const METER_PROPS = [
    // Read-Only Fields
    { key: 'Meter #', type: PROP_TYPES.NUMBER },
    { key: 'Service Address', align: 'left' },
    // { key: 'Meter Size' },
    { key: 'Outgoing Meter Type of Read' },
    { key: 'Outgoing Meter Brand' },
    { key: 'Outgoing Meter Serial #' },
    { key: 'Previous Reading' },
    // { key: 'Outgoing Meter ID' },
    // { key: 'Outgoing Meter Install Date' },
    // { key: 'Outgoing Meter Manufacturer' },
    // { key: 'Last Reading' },
    // { key: 'Last Reading Date' },
    // { key: 'Previous Reading' },
    // { key: 'Previous Reading Date' },
    // { key: 'Avg Use Amount' },
    // { key: 'Meter Type' },
    // { key: 'Group Code' },
    { key: 'Longitude' },
    { key: 'Latitude' },
    { name: 'Last Updated', key: 'Version', type: PROP_TYPES.ISO_TIMESTAMP },
    // Editable Fields
    {
        key: 'Crew Members',
        modifiable: true,
        type: PROP_TYPES.CREW_WITH_HOURS,
        rememberLast: true,
        crew: [
            'Greg Austin', 'Sherman Austin', 'CJ Boothe', 'Taylor Campbell', 'Brian Cook',
            'Devin Fowler', 'Hunter McCoy', 'Casey Perkins',
        ],
        onSave: function onCrewMembersSave(meter, lastUsedSettings) {
            const flogger = mlogger.getLogger('crew-members-onsave');
            flogger.logLevel = flogger.loggerLevels.debugall;
            // We only want output from this function when we're debugging issues, so we'll
            // log at a debug or debugall level in this function.
            flogger.debug(`Saving crew members (${JSON.stringify(meter['Crew Members'])}) for our default value`);
            if (!Array.isArray(meter['Crew Members'])) {
                lastUsedSettings.lastCrewSelected = undefined;
                return;
            }
            const lastCrew = [];
            for (let i = 0; i < meter['Crew Members'].length; i++) {
                flogger.debugall(`Massaging crew member ${meter['Crew Members'][i]}`);
                if (Array.isArray(meter['Crew Members'][i])) {
                    // If our crew member is an array, its probably an array of [Name, Hours] and
                    // we only want the name.
                    flogger.debugall('Crew member is an array');
                    lastCrew.push(meter['Crew Members'][i][0]);
                } else if (meter['Crew Members'][i].Name) {
                    // If our crew member is an object with a Name and Hours, grab just the name
                    // and set the hours to 0 for our default value
                    flogger.debugall('Crew member is an object with a Name prop');
                    lastCrew.push({
                        Name: meter['Crew Members'][i].Name,
                        Hours: 0,
                    });
                } else {
                    flogger.debugall('Crew member should be just strings');
                    lastCrew.push(meter['Crew Members'][i]);
                }
            }
            flogger.debug(`Value used for default value: ${JSON.stringify(lastCrew)}`);
            lastUsedSettings.lastCrewSelected = lastCrew;
        },
        defaultValue: function crewMemberDefault(lastUsedSettings) {
            if (lastUsedSettings && lastUsedSettings.lastCrewSelected) {
                return lastUsedSettings.lastCrewSelected;
            }
            return [];
        },
    }, {
        key: 'Work Type',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: [
            'Meter Install',
            'Site Survey',
        ],
        defaultValue: 'Meter Install',
    // }, {
    //     key: 'Install Type',
    //     modifiable: true,
    //     type: PROP_TYPES.SELECT,
    //     defaultValue: 'Replace Meter',
    //     choices: [
    //         'Transmitter Only', 'Replace Meter',
    //     ],
    // }, {
    //     key: 'Bill as Transmitter Install',
    //     modifiable: true,
    //     type: PROP_TYPES.BOOL,
    //     defaultValue: false,
    //     condition: function showCondition(meter) {
    //         return !meter['Install Type'] || meter['Install Type'] === 'Replace Meter';
    //     },
    }, {
        // depth to meter
        key: 'Depth to Meter',
        modifiable: true,
        type: PROP_TYPES.NUMBER,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        // Which size resitter? 7" or 9" sidewinder or retro or more depth?
        key: 'Resitter Size',
        modifiable: true,
        type: PROP_TYPES.SELECT_EDITABLE,
        choices: [
            '7"', '9" Sidewinder', 'Retro', 'More Depth',
        ],
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        // plastic box or concrete
        key: 'Box Type',
        modifiable: true,
        type: PROP_TYPES.SELECT_EDITABLE,
        choices: [
            'Plastic', 'Concrete',
        ],
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        // Commercial Service (Schedule Outage)
        key: 'Commercial Service (Schedule Outage)',
        modifiable: true,
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        // Ok to dug? hand or machine
        key: 'Dig Type',
        modifiable: true,
        type: PROP_TYPES.SELECT_EDITABLE,
        choices: [
            'Machine', 'Hand',
        ],
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        // Need town and engineer assistance to decide?
        key: 'Need town and engineer assistance to decide?',
        modifiable: true,
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        // concrete, asphalt or grass or sidewalk?
        key: 'Surface Type',
        modifiable: true,
        type: PROP_TYPES.SELECT_EDITABLE,
        choices: [
            'Concrete', 'Asphalt', 'Grass', 'Sidewalk',
        ],
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        // New box or old box?
        key: 'Box Age',
        modifiable: true,
        type: PROP_TYPES.SELECT_EDITABLE,
        choices: [
            'New', 'Old',
        ],
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        // Need an Expansion adapter??
        key: 'Expansion Adapter Needed',
        modifiable: true,
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        // Has a check valve or not?
        key: 'Check Valve Installed',
        modifiable: true,
        type: PROP_TYPES.BOOL,
        defaultValue: true,
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        key: 'Install Type',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: [
            'Meter Swap',
            'Meter Swap With Lid Replacement',
            'Meter Swap With Lid and Frame Replacement',
            'Meter Swap With Lid, Frame, and Box',
            'Meter Swap With New Resetter',
            'Meter Swap With New Resetter and Lid',
            // 'Meter Swap With New Resetter, Lid, and Box',
            // Replaced with Complete change out
            'Complete Change Out',
            'Meter Swap With New Resetter, Lid, and Frame Replacement',
            'MXU Only',
            'MXU With Lid',
            'MXU With Lid and Frame',
        ],
        defaultValue: 'Meter Swap',
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'Outgoing Meter Reading',
        modifiable: true,
        type: PROP_TYPES.NUMBER,
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
        // validationFunc: function outgoingMeterReadingValid(value, meter) {
        //     if (!value) {
        //         return 'A value must be entered.';
        //     }
        //     if (value < meter['Last Reading']) {
        //         return 'The meter reading is less than the previous reading.';
        //     }
        //     if (value > meter['Last Reading'] + 1000) {
        //         return 'The meter reading shows a consumption of more than
        // 1000 above the previous reading. Is it correct?';
        //     }
        //     // The current import file doesn't have a high consumption value, so we don't
        //     // have a way to check if the value is too high.
        //     // if (value > this.meter['Last Meter Reading'] + this.meter['High Cons
        //     //   umption']) {
        //     //     return 'This meter consumed more than it has ever previously consumed.
        //      Are you
        //     // sure you read it correctly?';
        //     // }
        //     return true;
        // },
        // completed: function isComplete(meter, prop) {
        //     return prop.validationFunc(meter['Outgoing Meter Reading'], meter) === true;
        // },
    }, {
        key: 'Meter Size',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: [
            '5/8x1/2', '2', '4', '1', '1.5', '1 1/2',
            // '0.75', '1', '1.5', '2', '4',
        ],
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'New Meter Reading',
        modifiable: true,
        type: PROP_TYPES.NUMBER,
        defaultValue: 0,
        validationFunc: function newMeterReadingValid(value) {
            if (value === undefined || value === '') {
                return 'A value must be entered.';
            }
            if (value > 10) {
                return `Most readings on new meters are less than 10. Are you sure the
                        value of ${value} is correct?`;
            }
            return true;
        },
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'New Meter ID',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'New Transmitter ID',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'Install Date',
        modifiable: true,
        type: PROP_TYPES.DATE,
        defaultValue: function dateDefault() {
            // The date component presents a default value. But, we need this function so the
            // save function can grab a default value for the save.
            const now = new Date();
            return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
        },
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'Concrete Dimensions',
        modifiable: true,
        optional: true,
        type: PROP_TYPES.DIMENSION,
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'Asphalt Dimensions',
        modifiable: true,
        optional: true,
        type: PROP_TYPES.DIMENSION,
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'Town Attention Needed',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        validationFunc: function newMeterReadingValid(value, meter) {
            if (value === true && !meter.Notes) {
                return 'If flagged as Town Attention Needed, notes must be provided.';
            }
            return true;
        },
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'Uninstallable',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        key: 'Notes',
        modifiable: true,
        type: PROP_TYPES.TEXTAREA,
        optional: true,
    },
];

const PHOTOS = [
    {
        name: 'Meter Site',
        shortName: 'survey-site',
        key: 'Photo URL Site Survey Meter Site',
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        name: 'Outgoing Meter',
        shortName: 'outgoing-meter',
        key: 'Photo URL Outgoing Meter',
        condition: function condition(meter) {
            return meter['Work Type'] === 'Site Survey';
        },
    }, {
        name: 'Final Reading',
        shortName: 'final-read',
        key: 'Photo URL Final Read',
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        name: 'New Meter Installed',
        shortName: 'new-meter',
        key: 'Photo URL New Meter',
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    }, {
        name: 'Area After',
        shortName: 'post-exchange',
        key: 'Photo URL Post Exchange',
        condition: function condition(meter) {
            return meter['Work Type'] !== 'Site Survey';
        },
    },
];

// Everything else is pre-computed arrays that can be overriden with custom arrays if needed

const METER_PROPS_BY_KEY = {};
const METER_PROPS_BY_NAME = {};
for (let i = 0; i < METER_PROPS.length; i++) {
    // Apply our default values
    if (METER_PROPS[i].name === undefined) {
        // If we didn't specify a name, the name is the same as the key
        METER_PROPS[i].name = METER_PROPS[i].key;
    }
    if (METER_PROPS[i].type === undefined) {
        // If we didn't specify a type, set as string. This is needed for sorting.
        METER_PROPS[i].type = PROP_TYPES.STRING;
    }
    // Text and value are used by the Vuetify data table header objects
    METER_PROPS[i].text = METER_PROPS[i].name;
    METER_PROPS[i].value = METER_PROPS[i].key;
    if (METER_PROPS[i].modifiable === undefined) {
        METER_PROPS[i].modifiable = false;
    }
    METER_PROPS_BY_KEY[METER_PROPS[i].key] = METER_PROPS[i];
    METER_PROPS_BY_NAME[METER_PROPS[i].name] = METER_PROPS[i];
}

const METER_DETAILS_PROPS = [];
for (let i = 0; i < METER_PROPS.length; i++) {
    if (!METER_PROPS[i].modifiable) {
        // If the prop is missing a modifiable attr or modifiable is false, lets assume its a
        // a prop to display with the details.
        METER_DETAILS_PROPS.push(METER_PROPS[i]);
    }
}

const METER_EDIT_PROPS = [];
for (let i = 0; i < METER_PROPS.length; i++) {
    if (METER_PROPS[i].modifiable) {
        // If the prop has the modifiable attr set to true, add it to our list.
        METER_EDIT_PROPS.push(METER_PROPS[i]);
    }
}

const INITIAL_SORT_PROPS = METER_PROPS.slice(0, 1);
const METER_LIST_COLS = METER_PROPS.slice(0, 3);

const PHOTOS_BY_TYPE = {};
for (let i = 0; i < PHOTOS.length; i++) {
    PHOTOS_BY_TYPE[PHOTOS[i].shortName] = PHOTOS[i];
}

// To use this file in a CommonJS environment replace export with module.exports =.
export {
    ROUTE_KEY, PROP_TYPES,
    FEATURE_TOGGLES, METER_PROPS, METER_PROPS_BY_KEY,
    METER_PROPS_BY_NAME, METER_DETAILS_PROPS, NUM_DETAILS_SHOW, METER_EDIT_PROPS,
    INITIAL_SORT_PROPS, METER_LIST_COLS, PHOTOS, PHOTOS_BY_TYPE,
};
